.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color-light: white; /*#e2e2e2*/
  --color-dark: #090909;
  --color-for-details: #6da7e1;
  --slogan-size: 11vmin;
  --h1-text-size: calc(var(--slogan-size) / (169 / 60));
  --h2-text-size: calc(var(--slogan-size) / (169 / 36));
  --h3-text-size: calc(var(--slogan-size) / (169 / 26));
  --h4-text-size: calc(var(--slogan-size) / (169 / 22)); /* 20px */
  --size-50-px: calc(var(--slogan-size) / (169 / 50));
  --size-100-px: calc(var(--slogan-size) / (169 / 100));
  --size-150-px: calc(var(--slogan-size) / (169 / 150));
}

body {
  background: var(--color-light);
  overflow-x: hidden;
}

html, body {
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
}

html::-webkit-scrollbar, body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Edge */
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 400vh;
  min-width: 100vw;
}

#navbar {
  width: 100vw;
  position: fixed;
  background-color: #0005;
  color: white;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--h2-text-size);
  font-weight: bold;
  height: calc(var(--h2-text-size) * (8 / 2.2));
  padding-top: 1vmin;
  padding-bottom: 1vmin;
}

.take-action {
  padding: calc(var(--slogan-size) / (169 / 20)) calc(var(--slogan-size) / (169 / 34));
  border-radius: 34px;
  text-align: left;
  text-wrap: nowrap;
  width: min-content;
  font-size: var(--h3-text-size);
  color: var(--color-for-details);
  background-color: white;
  
}

#navbar .take-action {
  float: right;
  margin-right: 2vmin;
  color: white;
  font-size: var(--h2-text-size);
  background-color: var(--color-for-details);
}

#navbar .home {
  float: left;
  margin-left: 2vmin;
  width: calc(var(--h2-text-size) * 7.15);
}

#navbar .why-move, #navbar .supporters, #navbar .how-to-support {
  margin-left: 1vmin;
  margin-right: 1vmin;
}

#navbar .middle-links {
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

#App {
  width: 100vw;
}

#home {
  text-align: center;
  position: relative;
}

#home h1 {
  font-size: var(--slogan-size);
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  color: white;
  bottom: 0.9vmin;
  z-index: 5;
  letter-spacing: 7px;
}

#home .header {
  height: 30vmin;
  position: relative;
  background-color: var(--color-dark);
}

#home img {
  width: 100%;
  /* height: 100vh;
  object-fit: cover;
  object-position: center; */
  margin-top: -7vmin;
  position: relative;
}

#home .img_container p {
  color: white;
  font-size: var(--h2-text-size);
}

#home .img_container div {
  text-align: right;
  transform: translateY(10%);
  left: 45%;
  position: absolute;
  z-index: 5;
  font-weight: bold; 
}

#home .text-over .cop-location {
  width: 120%;
  text-align: left;
  position: fixed;
  left: 20%;
  color: #F4DD09;
  font-size: var(--h1-text-size);
  background-color: var(--color-light);
  border-radius: 34px;
  padding-left: calc(var(--slogan-size) / (169 / 45));
  padding-top: calc(var(--slogan-size) / (169 / 20));
  padding-bottom: calc(var(--slogan-size) / (169 / 20));
}

#home .cop-location svg {
  height: var(--h1-text-size);
  margin-bottom: -0.5vmin;
}

#home .text-over p {
  position: relative;
  /* padding-top: calc(var(--slogan-size) / (169 / 140)); */
  padding-top: 40%;
}

#home .text-over p u {
  font-weight: bolder;
}

#home .text-over .take-action {
  margin-top: calc(var(--h2-text-size) / 2);
  /* visibility: hidden; */
  margin-top: 3%;
  margin-left: auto;
}

/* ---- */

.margin-top-150 {
  margin-top: var(--size-150-px)
}

/* ---- */

#intro-texts {
  display: flex;
  flex-direction: column;
  font-size: var(--h4-text-size);
  line-height: 150%;
  font-weight: 400;
}

#intro-texts .text-with-pic.left {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

#intro-texts .text-with-pic.right {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}

#intro-texts .text-with-pic:not(:first-child)  {
  margin-top: var(--size-100-px);
}

#intro-texts .text-with-pic .part {
  width: 44%;
}

#intro-texts .text-with-pic.left .part:last-child {
  margin-left: 6%;
}

#intro-texts .text-with-pic.left .part:first-child {
  margin-right: 6%;
}

#intro-texts .text-with-pic.right .part:last-child {
  margin-right: 6%;
}

#intro-texts .text-with-pic.right .part:first-child {
  margin-left: 6%;
}

#intro-texts .text-with-pic .text.part {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

/* ---- */

.max-width-content {
  max-width: calc(1440px * 0.83);
  margin-left: auto;
  margin-right: auto;
}

/* ---- */

.text-blck {
  text-align: center;
  font-size: var(--h1-text-size);
  font-weight: bold;
  text-transform: uppercase;
}

.text-blck .take-action {
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--size-50-px);
  text-transform: none!important;
}

#citation p.author {
  font-size: var(--h4-text-size);
  font-weight: normal!important;
  text-transform: none!important;
}

#citation p {
  margin-top: var(--size-50-px);
  padding-top: unset;
}

#citation p span {
  color: var(--color-for-details)
}

#citation svg {
  height: var(--h1-text-size);
}

/* ---- */

.first-text-blck {
  background: url('/public/images/text-1-bg.png') lightgray;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.first-text-blck .text {
  color: white;
}

.text-blck .text {
  padding-top: var(--size-150-px);
}

/* ---- */

@media (max-width: 450px) {
  #home h1 {
    font-size: calc(var(--slogan-size) - 2vmin);
  }
}

@media (max-width: 1000px) {
  :root {
    --h4-text-size: var(--h2-text-size);
    --h3-text-size: var(--h2-text-size);
  } 

  #intro-texts .text-with-pic {
    flex-direction: column!important;
  }

  #intro-texts .text-with-pic .part {
    margin-left: 0!important;
    margin-right: 0!important;
    width: 100%;
  }

  #intro-texts .text-with-pic .part:not(:first-child)  {
    margin-top: var(--size-100-px);
  }
}

@media (max-width: 1440px) {
  .max-width-content {
    margin-left: 8.5%;
    margin-right: 8.5%;
  }
}


